import React from "react";
import { ToastContainer } from "react-toastify";

function First() {
  return (
    <>
      <section className="terms-conditions">
        <div className="container">

          {/* Title */}
          <h1>WashMinute App – Account Deletion</h1>
          <p>Last Updated: February 28, 2025</p>

          {/* Introduction */}
          <h2>Introduction</h2>
          <p>
            At WashMinute, we respect your right to privacy and control over your personal data.
            If you wish to delete your account and associated data, this page provides all the necessary steps.
            Once deleted, your account cannot be recovered, and all related data will be permanently erased, except for legally required records.
          </p>

          {/* Steps to Delete Account */}
          <h2>How to Delete Your Account</h2>
          <p>Follow these steps to request account deletion:</p>

          <ol>
            <li>
              Open the WashMinute mobile application on your device.
            </li>
            <li>
              Navigate to the **Side Bar** section from the menu.
            </li>
            <li>
               look for the **Delete My Account** option.
            </li>
            <li>
              Tap on **Delete My Account** and confirm your request.
            </li>
            <li>
              Your request will be processed, and you will receive a confirmation once the deletion is complete.
            </li>
          </ol>

          {/* Alternative Method: Submit a Request */}
          <h2>Alternative Method: Request Deletion via Web</h2>
          <p>
            If you are unable to delete your account via the app, you can submit a deletion request via our website:
          </p>
          <ul>
            <li>
              Go to our official **Account Deletion Request Form** at:
              <a href="https://washminute.com/delete-account-request" target="_blank"> Delete Request Form ! </a>.
            </li>
            <li>
              Fill out the request form, including your **username** and **registered email**.
            </li>
            <li>
              Submit the form, and our team will review and process your request within 7 business days.
            </li>
          </ul>

          {/* What Happens After Account Deletion? */}
          <h2>What Happens After You Delete Your Account?</h2>
          <p>Once your account is deleted:</p>
          <ul>
            <li>All personal data, including your profile, wash history, and preferences, will be permanently deleted.</li>
            <li>Your login credentials will be removed, and you will no longer be able to sign in.</li>
            <li>Any pending or scheduled washes will be automatically canceled.</li>
            <li>
              Your **transaction history** may be retained for legal and compliance reasons (e.g., tax and regulatory requirements), but it will no longer be linked to your account.
            </li>
          </ul>

          {/* Data Retention Policy */}
          <h2>Data Retention Policy</h2>
          <p>
            In compliance with legal requirements, we may retain **some transaction data** (such as receipts and invoices) for regulatory purposes. 
            However, this data will be anonymized and no longer associated with your identity.
          </p>

          {/* Processing Time & Confirmation */}
          <h2>Processing Time</h2>
          <p>
            Once we receive your account deletion request, we will process it within **7 business days**.
            You will receive a final confirmation email once your account has been deleted.
          </p>

          {/* Recreating an Account */}
          <h2>Can You Recreate an Account After Deletion?</h2>
          <p>
            If you delete your account, you can create a new one in the future. However, your previous data **will not be recoverable**.
          </p>

          {/* Contact for Support */}
          <h2>Need Help?</h2>
          <p>
            If you experience any issues deleting your account, you can contact our support team at:
          </p>
          <ul>
            <li>Email: <a href="mailto:contact@washminute.com">contact@washminute.com</a></li>
            <li>Phone: +212-618509180</li>
            <li>Contact Form: <a href="https://washminute.com/contact" target="_blank">https://washminute.com/contact</a></li>
          </ul>

          <p>
            Thank you for using WashMinute! We appreciate your time with us, and if you ever decide to return, we welcome you back.
          </p>
        </div>
      </section>

      <ToastContainer />
    </>
  );
}

export default First;
