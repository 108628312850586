import React, { useEffect, useState, useRef } from 'react';
import AnimatedNumber from "animated-number-react";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingWater } from '@fortawesome/free-solid-svg-icons';
import { faCar, faShower } from '@fortawesome/free-solid-svg-icons';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';


function Find() {
  const { t } = useTranslation('home');


  const [stationValue, setStationValue] = useState(0);
  const [polishValue, setPolishValue] = useState(0);
  const [carsWashedValue, setCarsWashedValue] = useState(0);
  const [teamMembersValue, setTeamMembersValue] = useState(0);


  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {

            setStationValue(20);
            setPolishValue(300000);
            setCarsWashedValue(1200);
            setTeamMembersValue(5);
          } else {

            setStationValue(0);
            setPolishValue(0);
            setCarsWashedValue(0);
            setTeamMembersValue(0);
          }
        });
      },
      { threshold: 0.3 }
    );


    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }


    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <>

      <div className="container" ref={sectionRef}>
        <div className="row flex-lg-row-reverse">
          {/* <div className="col-lg-6">
              <div className="about-three__image wow fadeInRight" data-wow-duration="1500ms">
                <img src="assets/images/resources/about-3-1.jpg" className="img-fluid" alt="" />
              </div>
            </div> */}
          <div className="col-lg-12">
            <div className="about-three__content">
              <div className="section-title">
                <span className="section-title__tagline">{t("find.sectionTagline")}</span>
                <h2 className="section-title__title">{t("find.sectionTitle")}</h2>
              </div>
              {/* <div className="about-three__summery">
                  {t("find.sectionSummary")}
                </div> */}

              <ul className="about-three__counter list-unstyled">
                <li className="about-three__counter-item">
                  <FontAwesomeIcon
                    icon={faStore}
                    style={{ fontSize: '48px', color: '#ef530f', marginRight: '15px' }}
                  />
                  <div className="about-three__counter-content">
                    <h3 className="about-three__counter-number odometer">
                      <AnimatedNumber
                        value={stationValue}
                        duration={2500}
                        formatValue={v => Math.round(v)}
                      />
                    </h3>
                    <p className="about-three__counter-text">{t("find.stations")}</p>
                  </div>
                </li>
                <li className="about-three__counter-item">
                  <FontAwesomeIcon
                    icon={faHandHoldingWater}
                    style={{ fontSize: '48px', color: '#ef530f', marginRight: '15px' }}
                  />
                  <div className="about-three__counter-content">
                    <h3 className="about-three__counter-number odometer">
                      <AnimatedNumber
                        value={polishValue}
                        duration={1000}
                        formatValue={v => Math.round(v)}
                      /> L
                    </h3>
                    <p className="about-three__counter-text">{t("find.qualityPolish")}</p>
                  </div>
                </li>
                <li className="about-three__counter-item">
                  <FontAwesomeIcon
                    icon={faCar} 
                    style={{ fontSize: '48px', color: '#ef530f', marginRight: '15px' }}
                  />
                  <div className="about-three__counter-content">
                    <h3 className="about-three__counter-number odometer">
                      <AnimatedNumber
                        value={carsWashedValue}
                        duration={2500}
                        formatValue={v => Math.round(v)}
                      />
                    </h3>
                    <p className="about-three__counter-text">{t("find.carsWashed")}</p>
                  </div>
                </li>
                <li className="about-three__counter-item">
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar} 
                    style={{ fontSize: '48px', color: '#ef530f', marginRight: '15px' }}
                  />
                  <div className="about-three__counter-content">
                    <h3 className="about-three__counter-number odometer">
                      <AnimatedNumber
                        value={teamMembersValue}
                        duration={2500}
                        formatValue={v => Math.round(v)}
                      />%
                    </h3>
                    <p className="about-three__counter-text">{t("find.teamMembers")}</p>
                  </div>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      <br></br>

    </>
  );
}

export default Find;
