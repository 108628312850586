import React from 'react'
import { Route, Routes ,useLocation } from 'react-router-dom'
import Footer from '../components/Footer/Main'
// import Error from '../components/Error/Main'
import About from '../components/About/Main'
import Contact from '../components/Contact/Main'
import Home2 from '../components/Home2/Main'
import PartnersMain from '../components/Partners/PartnersMain/Main'
import NewsDetails from '../components/News/NewsDetails/Main'
import NewsMain from '../components/News/NewsMain/Main'
import OnlineBooking from '../components/OnlineBooking/Main'
import Pricing from '../components/Pricing/Main'
import Services from '../components/Service/Services/Main'
import ServiceDetails from '../components/Service/ServiceDetails/Main'
import Conditions from '../components/Conditions/Main'
import AccountDelete from '../components/AccountDelete/Main'
import AccountDeleteRequest from '../components/AccountDeleteRequest/Main'
import Daba from '../components/Daba/Main'
const Routing = () => {
    const location = useLocation();
    return (
        <>
            <Routes>

                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/" element={<Home2 />} />
                {/* <Route path="/error" element={<Error />} /> */}
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Route path="/faqs" element={<Faqs />} /> */}
                <Route path="/partners" element={<PartnersMain />} />
                <Route path="/newsmain" element={<NewsMain />} />
                <Route path="/newsdetails/:id" element={<NewsDetails />} />
                <Route path="/onlinebooking" element={<OnlineBooking />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/services" element={<Services />} />
                <Route path="/servicedetails/:id" element={<ServiceDetails />} />
                <Route path="/conditions" element={<Conditions/>} />
                <Route path="/delete-account" element={<AccountDelete/>} />
                <Route path="/delete-account-request" element={<AccountDeleteRequest/>} />
                <Route path="/daba" element={<Daba/>} />
            </Routes>
            {location.pathname !== "/daba" && <Footer />}
        </>
    )
}

export default Routing