import React from "react";
import { ToastContainer } from "react-toastify";

function First() {
  return (
    <>
      <section className="terms-conditions">
        <div className="container">

          {/* Title */}
          <h1>WashMinute App – Terms and Conditions</h1>
          <p>Last Updated: February 28, 2025</p>

          <p>
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. By downloading,
            installing, or using the WashMinute mobile application (the “App”),
            you (“you” or “User”) agree to be bound by the following Terms and
            Conditions (“Terms”). These Terms constitute a legally binding
            agreement between you and WashMinute,
             headquartered at 345 AL MASSAR ROUTE DE SAFI, Marrakech (Carrefour Targa), Morocco(the “Company,” “we,” “us,” or
            “our”). If you do not agree with any part of these Terms, you must
            not use the App or the services provided through it. These Terms
            apply to all users of the App, including Clients (users requesting
            car wash services) and Washmen (independent service providers
            offering car wash services). Additional guidelines or rules may
            apply to certain features of the App; those guidelines are
            incorporated by reference into these Terms. We also adhere to all
            applicable Google Play Store policies, and these Terms are intended
            to comply with those requirements. In the event of any conflict
            between these Terms and the Google Play policies or applicable law,
            the Google Play policies (and/or applicable law) will prevail.
          </p>

          {/* 1. Definitions */}
          <h2>1. Definitions</h2>
          <p>
            For clarity, certain terms used in these Terms are defined as
            follows:
            <br />
            “Client” – A user of the App who requests and receives car wash
            services from a Washman through the App.
            <br />
            “Washman” – A user of the App who offers and provides car washing
            services (service provider). Washmen may be independent contractors
            or partners authorized to use the App to connect with Clients.
            <br />
            “Services” – The on-demand mobile car wash services facilitated
            through the WashMinute App, including the platform features that
            allow Clients and Washmen to connect, schedule, and carry out car
            washing appointments.
            <br />
            “Company” or “WashMinute” – The operator of the App and platform
            (WashMinute) that facilitates the connection between Clients and
            Washmen. This may refer to the owning entity of the App.
          </p>

          {/* 2. General Terms of Use and User Accounts */}
          <h2>2. General Terms of Use and User Accounts</h2>
          <h3>2.1 Acceptance of Terms</h3>
          <p>
            By creating an account or using the App, you affirm that you have
            read and understood these Terms and agree to abide by them. You also
            represent that you are at least the age of majority in your
            jurisdiction (typically 18 years or older) and are legally capable
            of entering into this agreement. If you are using the App on behalf
            of a company or other entity, you represent that you have authority
            to bind that entity to these Terms.
          </p>

          <h3>2.2 Account Registration</h3>
          <p>
            To use the Services as either a Client or a Washman, you must create
            an account and provide accurate, current, and complete information
            as requested. You are responsible for maintaining the
            confidentiality of your account login credentials and for all
            activities that occur under your account. You agree to immediately
            notify the Company of any unauthorized use of your account or any
            other breach of security. We reserve the right to suspend or
            terminate your account if any information provided during
            registration or thereafter is false, violates these Terms, or
            otherwise creates risk or harm to us or other users.
          </p>

          <h3>2.3 Platform Role</h3>
          <p>
            WashMinute provides a platform to connect Clients seeking car wash
            services with Washmen who can provide those services. We act solely
            as an intermediary between Clients and Washmen. The Company is not
            itself providing car wash services; those services are provided by
            the Washmen as independent third-party service providers (unless
            otherwise stated that they are our employees or agents). We do not
            guarantee the performance, actions, or omissions of any user (Client
            or Washman). Any agreements or dealings between a Client and a
            Washman (aside from these platform Terms) are solely between those
            parties. However, both Clients and Washmen must adhere to these
            Terms when using the App.
          </p>

          <h3>2.4 Rights and Responsibilities of Clients and Washmen</h3>
          <p>
            <strong>Clients’ Rights:</strong> As a Client, you have the right to
            use the App to request car wash services in a timely, professional
            manner. You can view profiles or ratings of Washmen (if available),
            and you may choose among available service options. You are entitled
            to receive the service as described at the agreed-upon price. You
            may report issues or misconduct to the Company and provide feedback
            or ratings for services received.
            <br />
            <strong>Clients’ Responsibilities:</strong> Clients agree to use the
            service fairly and responsibly. This includes providing accurate
            information about your vehicle (e.g. type, size, condition) and
            location for the wash, being present or ensuring access to the
            vehicle at the scheduled time, and paying the Washman the agreed
            price upon completion. Clients must treat Washmen with respect and
            refrain from any abusive, fraudulent, or inappropriate behavior.
            Clients are responsible for any preparation needed (such as securing
            valuables in the vehicle) before the wash. If a Client fails to be
            available or to provide access to the vehicle at the appointed time,
            or cancels late (see Section 5 below), the Client may be responsible
            for cancellation fees or may be denied future service for repeated
            violations.
            <br />
            <strong>Washmen’s Rights:</strong> As a Washman (service provider),
            you have the right to accept or decline service requests at your
            discretion (within any App guidelines). You are entitled to prompt
            payment from the Client (in cash, unless otherwise updated) upon
            completing an accepted job. You may access tools in the App to
            manage your availability and view Client requests. You also have the
            right to a safe work environment; if a Client’s behavior or
            situation is unsafe or violates these Terms, you may refuse or
            discontinue a service (with notice to the Client and the Company).
            Washmen can also provide feedback or ratings about Clients, and
            report any issues to the Company.
            <br />
            <strong>Washmen’s Responsibilities:</strong> Washmen agree to
            maintain professional conduct and high-quality service standards.
            This means arriving on time for accepted appointments, or
            communicating promptly with the Client if there are delays or issues
            finding the location. Washmen should perform the car wash service
            with due care, skill, and in accordance with the description
            provided to the Client (e.g., if a certain package or level of
            cleaning is requested, fulfill those requirements). They must have
            and bring all necessary equipment and supplies for the wash, unless
            explicitly agreed otherwise. Washmen are responsible for any damage
            they may cause to a Client’s vehicle or property through negligence
            or misuse of materials, and they should carry any required insurance
            or licenses as mandated by local law. Additionally, Washmen agree
            not to solicit Clients for external jobs off the platform or engage
            in any behavior that would circumvent the App’s processes or payment
            terms. All Washmen must comply with applicable laws and regulations
            while using the App and providing services (including driving laws
            when traveling to a client, environmental regulations for handling
            water/waste, etc.).
          </p>

          <h3>2.5 Use of the App</h3>
          <p>
            All Users (Clients and Washmen alike) agree to use the App only for
            its intended purpose of facilitating on-demand car wash services.
            You must not misuse the App in any way that could impair the
            functionality of the platform or other users’ experience. Prohibited
            activities include, but are not limited to: introducing viruses or
            harmful code; attempting to gain unauthorized access to the App or
            other users’ accounts; data mining or scraping the App without
            permission; or using the App to harass, threaten, or harm any
            person. We reserve the right to monitor usage and communications
            within the App (in accordance with our Privacy Policy and
            applicable law) to ensure compliance with these Terms and to take
            appropriate action, including suspension or termination of accounts,
            if we suspect any misuse or abuse of the platform.
          </p>

          {/* 3. Service Use and Scope */}
          <h2>3. Service Use and Scope</h2>
          <p>
            WashMinute offers an on-demand mobile car wash service platform.
            Here’s how it works and what is included in our service scope:
            <br />
            <strong>Requesting a Service (Clients):</strong> As a Client, you
            can use the App to create a car wash request by specifying details
            such as your vehicle’s location, type of service (for example,
            exterior wash, interior cleaning, full detail, etc., as available in
            the App), and preferred time. Once your request is submitted, it
            will be visible to Washmen in your area who are logged into the App
            and available to work.
            <br />
            <strong>Accepting and Fulfilling a Request (Washmen):</strong>{" "}
            Washmen can browse or receive notifications of nearby car wash
            requests. A Washman may accept a request through the App, thereby
            entering into an agreement to complete that service for the Client.
            Once a Washman accepts, the Client will be notified with the
            Washman’s details (such as name, profile, and estimated time of
            arrival). The Washman is then expected to travel to the Client’s
            specified location at the agreed time and perform the requested car
            wash service.
            <br />
            <strong>Geographical Scope:</strong> The service is available in
            specific geographic areas as indicated in the App. We may use
            location data (with user permission) to match Clients with nearby
            Washmen. The availability of Washmen and the ability to request a
            wash may vary based on time of day, region, and Washmen availability.
            We do not guarantee that a Washman will be available to accept every
            request or that every area is serviced. If no Washman accepts your
            request within a reasonable time, the request may expire, or you may
            be prompted to try again later.
            <br />
            <strong>Nature of Services:</strong> WashMinute facilitates various
            types of car cleaning services, which may range from basic washing
            to more comprehensive detailing. The exact services offered, their
            descriptions, and their pricing are provided in the App’s interface
            when you place a request. We strive to ensure that Washmen only
            offer services they are qualified to perform. Important: While the
            Company sets general quality guidelines and may fix service
            packages, the actual work is done by Washmen. We do not supervise
            each service in person, and the outcome may vary based on the
            Washman’s skill and conditions. Any issues or disputes about the
            service provided should be addressed as described in Section 8
            (Liability and Disclaimers) below.
            <br />
            <strong>Scheduling and Timing:</strong> When a service request is
            accepted, the App will indicate an estimated time for the Washman’s
            arrival and the approximate duration of the service. These times are
            estimates and not guaranteed. External factors like traffic,
            weather, or unforeseen delays can affect timing. Both Clients and
            Washmen should communicate via the App (or via provided contact
            information) if there are significant timing changes or difficulties
            in locating the address. We encourage professional and prompt
            communication to ensure smooth service.
            <br />
            <strong>Scope Limitations:</strong> The App’s role is limited to
            matchmaking and support. Any actual contract for services is between
            the Client and the Washman for that specific job. We do not provide
            tools or cleaning agents to Washmen; they are independent in how
            they fulfill the job (though they must meet our standards). The
            Company is not responsible for any outside arrangements made between
            Client and Washman that are not facilitated through the App (for
            example, if a Client separately hires a Washman for additional work
            outside the App, we are not party to that and these Terms may not
            cover that separate arrangement). Additionally, the Services do not
            include any maintenance or mechanical work on vehicles—only cleaning
            and cosmetic services as listed in the App. Washmen should not be
            asked to perform services outside the agreed scope (for instance,
            repairing a car or any illegal activity), and they have the right to
            refuse any requests that fall outside the originally agreed-upon
            service or these Terms.
          </p>

          {/* 4. Payment Terms */}
          <h2>4. Payment Terms</h2>
          <p>
            All payments for car wash services arranged through WashMinute are
            subject to the following terms:
            <br />
            <strong>Cash on Delivery (COD):</strong> Cash payment upon service
            completion is currently the only accepted payment method. Clients
            must pay the Washman directly in cash for the amount agreed upon in
            the App once the service is completed satisfactorily. The App will
            display the price for the service at the time of request
            confirmation. It is the Client’s responsibility to have the
            appropriate amount in cash available to pay the Washman at the end
            of the service. Washmen are responsible for collecting payment from
            the Client at the completion of the job. There is no in-app payment
            processing at this time.
            <br />
            <strong>No In-App Purchases (Presently):</strong> Because payments
            are handled in cash offline, there are no charges billed through the
            App or via credit/debit card or other electronic methods for the
            service itself. (Note: The App’s download and usage are free aside
            from any data charges by your mobile provider. Creating an account
            and browsing is free; you only pay for actual services you request
            and receive.)
            <br />
            <strong>Future Payment Methods:</strong> The Company may introduce
            additional payment options in the future (such as credit card
            payments, mobile wallet, or in-app payments). If we do so, we will
            update these Terms accordingly and notify users of the new payment
            features. Any new payment method will be implemented in compliance
            with applicable laws and Google Play policies. For example, if
            in-app digital payments are introduced, they may be processed
            through secure third-party payment processors or via Google Play’s
            payment system as required. Until such features are launched, Users
            should not attempt to make payment through any method not explicitly
            allowed by the App.
            <br />
            <strong>Pricing and Accuracy:</strong> The prices for standard
            services are set by the Company and listed in the App. We strive to
            ensure that all pricing displayed is clear and inclusive of any
            applicable taxes or fees. If a Washman arrives and finds that the
            scope of the work is significantly different than requested (for
            example, the vehicle is excessively dirty beyond normal conditions,
            or the requested service doesn’t match the vehicle type), the
            Washman should contact the Company or adjust the service in the App
            (with the Client’s agreement) before starting the job, which may
            result in a price adjustment. Any such adjustments must be agreed by
            the Client through the App or in writing. Clients should confirm the
            price shown in the App before the service begins, and Washmen should
            only charge the amount that was confirmed. Overcharging or price
            gouging outside the App’s stated price is a violation of these
            Terms.
            <br />
            <strong>Receipts:</strong> After a service is completed and payment
            is made, the App may provide an electronic receipt or summary of the
            transaction to the Client (e.g., via email or in-app notification).
            This receipt will detail the service provided, date/time, Washman’s
            name, and amount paid. Because payment is in cash, this receipt is
            for record-keeping and feedback purposes; it is not a credit card
            receipt. Clients are advised to keep this for their records. Washmen
            should mark the job as complete in the App once paid, to ensure
            proper logging of the transaction.
            <br />
            <strong>No Service Fees from Company (For Now):</strong> The Company
            does not currently charge Clients any additional platform or booking
            fee on top of the service price. (The Washmen may pay a commission
            or fee to the platform as per a separate agreement with the Company,
            but that is not covered in these Terms with Clients.) If any
            platform fees for Clients are introduced in the future, they will be
            clearly disclosed and the Terms will be updated to reflect how those
            are applied.
          </p>

          {/* 5. Cancellations and Refunds */}
          <h2>5. Cancellations and Refunds</h2>
          <p>
            We understand that schedules can change. This section explains our
            cancellation policy for both Clients and Washmen, and our no-refund
            policy under most circumstances:
          </p>

          <h3>5.1 Cancellation by Clients:</h3>
          <p>
            <strong>Before Acceptance:</strong> A Client may cancel a car wash
            request at any time before a Washman has accepted the request,
            without penalty. Since no Washman has been assigned, no payment is
            due and the request will simply be voided.
            <br />
            <strong>After Acceptance (Early):</strong> If a Client needs to
            cancel after a Washman has accepted the request but before the
            Washman has traveled to the location, the Client should do so as
            early as possible via the App’s cancellation feature or by
            contacting the Washman/Company. We encourage timely cancellations as
            a courtesy to the Washman. In general, if the cancellation is made
            well in advance of the appointment time (e.g., more than [X] minutes
            beforehand, if specified in app guidelines), there will be no
            penalty. The App may allow a brief window for free cancellation
            after acceptance.
            <br />
            <strong>After Dispatch/En Route:</strong> If a Client cancels after
            the Washman is already en route to the location or has arrived, this
            is considered a late cancellation. Because payment is cash on
            delivery, the Client will not be charged by the App; however, late
            cancellations disrupt Washmen’s schedules and may incur
            consequences. The Company reserves the right to charge a
            cancellation fee in the future for such situations or to ban users
            who repeatedly cancel at the last minute. If a cancellation fee
            policy is in place (check the App or latest Terms), the Client would
            be responsible for paying that fee (possibly at the next successful
            transaction or via separate arrangement). For now, we may record the
            late cancellation in your account history, and repeated incidents
            could lead to warnings or suspension of your account.
            <br />
            <strong>No-Show by Client:</strong> If a Client does not show up or
            fails to provide access to the vehicle at the scheduled time and
            location (and is unresponsive to contact attempts), the Washman may
            treat the situation as a cancellation. In such cases, the same
            policy as a late cancellation applies. The Client should not expect
            the Washman to wait indefinitely—Washmen will wait a reasonable time
            (e.g., a few minutes past the appointment) and attempt contact.
            After that, they may leave. This will be noted in the system, and
            repeated no-shows may result in account action. Since no payment was
            made in advance, no refund is due; however, the Client has
            inconvenienced the Washman and the platform and this behavior is
            taken seriously.
          </p>

          <h3>5.2 Cancellation by Washmen:</h3>
          <p>
            A Washman may cancel an accepted request in emergency or unavoidable
            situations (for instance, vehicle breakdown, sudden illness, unsafe
            conditions at the location, etc.). Washmen are expected to minimize
            cancellations as it impacts Clients’ experience. If a Washman must
            cancel, they should do so at the earliest opportunity and
            communicate the reason if possible. The App will then attempt to
            reassign the request to another available Washman, or notify the
            Client of the cancellation. The Company monitors cancellation rates,
            and Washmen with frequent cancellations without valid reason may
            face suspension from the platform. Clients will not be charged for
            any cancellation by a Washman, and if pre-payment ever occurred (in
            a future payment system scenario), the Client would be fully refunded
            for cancellations where service wasn’t delivered.
          </p>

          <h3>5.3 No Refund Policy:</h3>
          <p>
            Because payment is made in cash only after the service is completed,
            there is typically no scenario where the Company holds a Client’s
            funds that would require a refund. The Company itself does not
            collect the service payment (it is paid directly to the Washman).
            Therefore, once a service is completed and paid, that transaction is
            considered final. The App does not provide refunds for completed
            services that were paid in cash. If you are unsatisfied with the
            service quality, you should rate the Washman accordingly and can
            contact customer support to report the issue, but the Company does
            not issue refunds of cash transactions. Any remedy or redo would
            need to be arranged directly with the Washman or through a new
            service request.
            <br />
            In the event that a future update enables pre-payment or charges
            through the App, the refund policy will be updated and may include
            specifics (for example, no refunds once service is performed, or
            refunds only in cases of confirmed no-show or etc.). For now, by
            using the App, you acknowledge that no refund of any kind is offered
            by the Company for the car wash services, since the Company does not
            process those payments. All payments are settled directly between
            Client and Washman.
            <br />
            <strong>Exception – Service Not Provided:</strong> If a Client
            prepaid (in a hypothetical future scenario) and the service was
            never provided (e.g., no Washman showed up, or the Washman canceled
            and no replacement came), then the Client would be entitled to a
            refund of any amount charged. In the current cash model, this
            situation means the Client simply doesn’t pay anything (since no
            service occurred). Clients should never pay a Washman if the service
            was not completed. If a Washman tries to collect payment without
            completing the job, report this to the Company immediately.
          </p>

          <h3>5.4 Rescheduling:</h3>
          <p>
            Instead of canceling, a Client may request to reschedule an
            appointment (if the functionality is supported in-app or by
            contacting support). Rescheduling is subject to Washman availability
            and agreement. If a Washman cannot accommodate the new time, the
            request might be treated as a cancellation and handled as above.
            There is no fee for rescheduling itself, but last-minute reschedule
            requests might be treated similarly to late cancellations if they
            effectively cancel the original booking.
          </p>

          <h3>5.5 Changes to Cancellation/Refund Policy:</h3>
          <p>
            The Company may introduce a formal cancellation fee or policy as the
            platform grows (for example, a small fee for cancellations within X
            minutes of the appointment). Any such changes will be reflected in
            these Terms and communicated in-app. Users will be expected to abide
            by the updated policy after notification. We will always strive to
            keep these policies fair, transparent, and in line with common
            on-demand service practices.
          </p>

          {/* 6. User Responsibilities and Conduct */}
          <h2>6. User Responsibilities and Conduct</h2>
          <p>
            All users of WashMinute are expected to adhere to the following
            guidelines for fair usage, professional conduct, and to avoid any
            abuse of the platform:
            <br />
            <strong>Compliance with Law:</strong> You must use the App and
            Services in compliance with all applicable laws and regulations.
            Clients must only request services for vehicles and locations that
            they have the right to use and where performing a car wash is legal
            (for example, not requesting a wash on private property where you
            don’t have permission, or in violation of local water use
            regulations). Washmen must comply with any licensing requirements,
            traffic laws (when traveling to Clients), and environmental or
            disposal regulations related to car washing (such as proper disposal
            of dirty water or not using certain chemicals in prohibited areas).
            <br />
            <strong>Respectful and Non-Abusive Behavior:</strong> Users shall
            treat each other with courtesy and respect. Any form of harassment,
            hate speech, abuse, or discrimination is strictly prohibited.
            Clients should not harass or threaten Washmen, and Washmen should
            not harass or threaten Clients. Both parties should refrain from any
            inappropriate behavior during their interactions (in communications
            via the App, during the in-person service, and afterwards). This
            includes but is not limited to: using derogatory language, making
            unwelcome advances, or engaging in any physically or verbally
            abusive conduct.
            <br />
            <strong>No Fraud or Misrepresentation:</strong> You agree not to
            defraud, deceive, or mislead the Company or other users. Clients
            must not request services under false pretenses (for example,
            misrepresent the condition or size of the vehicle deliberately to
            get a lower price, or use a fake location). Washmen must not claim
            to have qualifications or abilities they do not possess, and must
            not mark a job as complete or request payment if the service wasn’t
            actually provided fully. Any form of fraud (such as using stolen
            accounts, credit cards if introduced, or falsifying transaction
            details) can result in immediate termination of your account and
            possible legal action.
            <br />
            <strong>Fair Use of the Platform:</strong> Users should make
            requests and accept jobs in good faith. The platform should not be
            misused – for example, Clients should not repeatedly create and
            cancel requests with no intention of getting a service, and Washmen
            should not accept jobs and then routinely cancel them or fail to
            show up. Such behavior undermines the platform’s reliability. We
            reserve the right to investigate patterns of misuse and take
            corrective actions (like temporary suspension, penalties, or
            permanent bans for accounts that abuse the system).
            <br />
            <strong>Professional Conduct (Washmen):</strong> Washmen are
            expected to maintain a professional demeanor. This includes dressing
            appropriately (as reasonably expected for a service job), carrying
            proper tools, and respecting the Client’s property. Do not smoke in
            or near the Client’s vehicle without permission, do not play loud
            music or cause disturbances at the Client’s location, and ensure
            that after the service, the area is left in a reasonably clean state
            (for example, properly dispose of any used water or materials, don’t
            leave trash behind). Washmen should follow any additional guidelines
            provided by the Company for quality service.
            <br />
            <strong>Vehicle and Property Respect (Clients):</strong> Clients
            should remove or secure any fragile or valuable items in the vehicle
            prior to the wash. While Washmen will do their best to avoid any
            disturbance of personal items, the Company is not liable for items
            that were left in the car. It’s the Client’s responsibility to
            ensure the Washman has clear access to the areas of the vehicle that
            need cleaning (e.g., unlocking the car, opening trunk if interior
            work, etc.) at the time of service.
            <br />
            <strong>Feedback and Ratings:</strong> To maintain quality, the App
            may allow Clients to rate or review the Washmen and vice versa. If
            you provide feedback, you agree to do so honestly and based on real
            experience. Any content you post in a review or feedback must be
            factual and not abusive. We reserve the right to remove or edit
            feedback that violates our content policies or these Terms (for
            example, if it contains profanity, personal attacks, or confidential
            information). However, we do not routinely censor reviews and the
            opinions expressed are those of the users, not the Company.
            <br />
            <strong>No Third-Party Transactions:</strong> All service requests,
            communications about scheduling, and payments (aside from the cash
            handover) should be handled through the App. Clients and Washmen
            agree not to circumvent the platform by arranging washes off-app
            with users they met through WashMinute, as this can violate platform
            policies and potentially void insurance or support mechanisms we
            have in place. Engaging in repeated off-platform transactions that
            were initiated on WashMinute may lead to account termination. The
            App sustains itself by the network and possibly commissions;
            bypassing it undermines the service for all.
            <br />
            <strong>Device and Account Security:</strong> You are responsible
            for ensuring that the device you use the App on is secure. Do not
            share your account credentials with anyone else. You must not use
            another user’s account or allow someone to use yours. In the case of
            Washmen, any person performing the service should be the same
            individual whose identity is tied to the Washman’s account (no
            “swapping” providers without updating the account and going through
            any necessary verification). Keep your app updated to the latest
            version for security patches and improved features.
            <br />
            <strong>Reporting Issues:</strong> If at any time you feel another
            user is violating these guidelines – for example, if a Client is
            being abusive, or if a Washman is doing something unsafe or
            unethical – please report it to us. There is likely a feature in-app
            or you can contact customer support at the contact details provided
            in these Terms. We will review reports and take appropriate action,
            which may include warnings, suspension, or involving law enforcement
            if necessary.
          </p>

          {/* 7. Data Privacy & Security */}
          <h2>7. Data Privacy & Security</h2>
          <p>
            Your privacy and data security are extremely important to WashMinute.
            We want you to feel confident that your personal information is
            protected and handled properly. Please review our Privacy Policy
            (available via the App or our website) for full details on how we
            collect, use, store, and protect your data. The key points related
            to privacy and security in the context of these Terms are:
            <br />
            <strong>Personal Data Collection:</strong> When you register and use
            the App, we will collect certain personal information, such as your
            name, contact information (phone number, email), location (for
            Clients when requesting a wash, and for Washmen to determine service
            areas), and possibly payment-related information if and when new
            payment methods are introduced. Washmen may also provide
            identification or verification documents as part of their profile.
            We collect and process this data in order to provide the Services
            (e.g., to connect Clients with nearby Washmen, to facilitate
            communication, etc.).
            <br />
            <strong>Use of Data:</strong> Any personal data collected is used in
            accordance with our Privacy Policy and applicable data protection
            laws. Generally, client data is kept private and is only shared with
            others as needed to deliver the service. For example, when you
            request a wash, relevant details (your first name, location of the
            car, vehicle details, and contact number) will be shared with the
            Washman who accepts your request so they can find you and perform
            the service. Similarly, Washmen’s profiles (name, photo, ratings,
            etc.) are visible to Clients who might receive their service. We do
            not sell your personal information to third parties. We may use some
            data in aggregate or anonymized form for analytics to improve the
            App, but not in a way that identifies you personally.
            <br />
            <strong>Data Security:</strong> We implement industry-standard
            security measures to protect your information. This includes
            encryption of sensitive data where appropriate, secure servers, and
            other technical and organizational measures to guard against
            unauthorized access, theft, or misuse of data. However, please
            understand that no method of transmitting or storing data is 100%
            secure. By using the App, you acknowledge that there is some
            inherent risk in transmitting data over the internet and that we
            cannot guarantee absolute security. We do, however, commit to
            promptly notifying users in accordance with applicable laws if there
            is any breach of our systems that compromises your personal data.
            <br />
            <strong>User Control &amp; Data Deletion:</strong> You have control
            over your personal data stored in the App. You can access and update
            your profile information at any time by logging into your account
            settings. If you wish to delete your data or your account entirely,
            you have the right to request deletion. In compliance with Google
            Play’s User Data policies and applicable privacy regulations (such
            as GDPR, etc.), we provide an option for you to request full
            deletion of your account and associated personal data. This can
            typically be done via an in-app “Delete Account” feature or by
            sending a request to our support email [we will provide contact info
            in Section 12]. Once we receive such a request, we will verify it
            (for security, to confirm it’s really you) and then proceed to
            delete your data from our active databases within a reasonable
            timeframe, except for any information we are required to retain for
            legal or regulatory purposes (for example, records of transactions
            for accounting, or information needed to enforce our rights). After
            deletion, your profile will be removed and you will no longer be
            able to log in. Keep in mind that it may take a short period for
            backup systems to fully purge data, but we will not use your data
            after you’ve requested deletion except as required by law.
            <br />
            <strong>Confidentiality:</strong> We treat your communications
            through the App as private, however, to the extent necessary to
            operate the service (for example, relaying chat messages or storing
            instructions), the system will process them. We do not listen to or
            store voice calls if the App allows you to call the other party
            (calls may be either through the app or using masked phone numbers).
            If we introduce any recording or monitoring (for safety or quality),
            we will disclose it. For now, any call between Client and Washman is
            not recorded by us. Any personal information exchanged between
            Client and Washman during the service (such as handing a phone
            number or address) should be handled with care by both parties and
            used only for the purposes of the service.
            <br />
            <strong>Privacy of Client Data for Washmen:</strong> Washmen
            receiving client information (like address, phone number, vehicle
            details) must treat that information as confidential and only use it
            for the purpose of fulfilling the wash service. Washmen should not
            save clients’ personal information beyond what the App provides, nor
            use it for any other reason (such as marketing their own services
            outside the platform) without explicit permission from the Client.
            Misuse of Client data by a Washman is a serious violation and could
            lead to legal consequences and immediate termination of access to
            the App.
            <br />
            <strong>Tracking and Analytics Data:</strong> The App may
            automatically collect certain usage data to help us improve the
            service and fix issues (for example, app crash reports, feature
            usage statistics, etc.). This data is generally anonymized or
            aggregated, but some identifiers (like a device ID or user ID) might
            be processed. By using the App, you consent to this collection and
            use of analytics data. All third-party analytics data is handled
            according to our Privacy Policy. We do not permit these third
            parties to use your data for their own purposes beyond providing
            services to us.
          </p>

          {/* 8. Third-Party Services and Integrations */}
          <h2>8. Third-Party Services and Integrations</h2>
          <p>
            WashMinute integrates certain third-party services to provide
            important features, such as mapping and analytics. By using our App,
            you also agree to and acknowledge the following regarding
            third-party services:
            <br />
            <strong>Google Maps Integration:</strong> The WashMinute App uses
            Google Maps API(s) to provide location-based services, including
            displaying maps of service locations, assisting Washmen in
            navigation to the Client’s location, and helping Clients pinpoint
            their desired service location. As a result, by using the App, you
            are also agreeing to be bound by Google’s Terms of Service and
            Google Maps/Google Earth Additional Terms of Service, as well as
            Google’s Privacy Policy. These can be found at:
            <br />
            Google Maps/Google Earth Additional Terms of Service:
            https://maps.google.com/help/terms_maps
            <br />
            Google Privacy Policy: https://policies.google.com/privacy
            <br />
            Any maps data, location data, or directions provided in the App are
            subject to Google’s terms. We do not guarantee the accuracy of any
            maps or location information provided by Google within our App.
            Users should use their own judgment and obey local traffic laws when
            following directions.
            <br />
            <strong>Location Services:</strong> For the App to function properly
            (especially for Clients requesting service and Washmen fulfilling
            them), the App may request access to your device’s location (GPS
            coordinates). Granting this permission allows the App to suggest
            your current location for service, show your proximity to a Washman,
            or vice versa. If you deny location access, some features may not
            function (for example, you might have to manually enter an address).
            Location data may be shared between Client and Washman during an
            active request to facilitate the meeting. We handle location data as
            personal data under our Privacy Policy. If location tracking of
            Washmen is enabled (for example, to show the Client that the Washman
            is en route on a map), that is only during an active job and not
            when a Washman is offline or off-duty. We do not share real-time
            location of users publicly, only between the transacting parties for
            the purpose of the service.
            <br />
            <strong>Analytics and Crash Reporting:</strong> The App may use
            third-party analytics tools or SDKs (Software Development Kits) such
            as Google Analytics for Firebase, Firebase Crashlytics, or other
            similar services. These tools help us understand how users interact
            with our App (e.g., which features are most used, how often services
            are requested, etc.) and to diagnose crashes or bugs quickly. Data
            collected by these third-party tools may include technical
            information about your device (device model, OS version, App
            version), usage information (screens visited, buttons clicked,
            timestamps), and in the event of a crash, possibly the state of the
            app that led to the crash. This information is generally anonymized
            or aggregated, but some identifiers (like a device ID or user ID)
            might be processed. By using the App, you consent to this collection
            as it is necessary for us to maintain a high-quality service. All
            third-party analytics data is handled according to our Privacy
            Policy and the third parties’ privacy policies. We do not permit
            these third parties to use your data for their own purposes beyond
            providing services to us.
            <br />
            <strong>Third-Party Payment Processors:</strong> (Applicable in
            future if introduced) If we add credit card or digital payments,
            those transactions might be handled by third-party payment
            processors (for example, Stripe, PayPal, Google Pay, etc.). If and
            when you use such a payment method, you will be subject to the terms
            and privacy policies of those third-party services in addition to
            our own Terms. We will provide information in the app about any such
            integration. For now, with cash only, this is not applicable.
            <br />
            <strong>External Links:</strong> The App or our website might
            contain links to third-party websites or resources (for example, a
            link to our Facebook page, or an insurance provider for Washmen,
            etc.). These links are provided for convenience only. We do not
            endorse and are not responsible for the content, advertising,
            products, or other materials on or available from those sites or
            resources. If you click such links, you do so at your own risk, and
            you should review the terms and privacy policies of any third-party
            sites you visit.
            <br />
            <strong>Third-Party Open-Source Components:</strong> The App may
            include open-source software components developed by third parties,
            which are subject to their own licenses. While this is more relevant
            to developers, we mention it here for transparency. Using the App
            implies compliance with those licenses; a list of any such
            components and their licenses can typically be found in an “About”
            or “Licenses” section within the App.
            <br />
            <strong>No Third-Party Beneficiaries:</strong> These Terms are
            between you and WashMinute. No other person or company shall be a
            third-party beneficiary to these Terms, except to the limited extent
            that certain provisions expressly reference third-party terms (like
            Google’s terms above) which you are agreeing to. This means, for
            example, that while we integrate Google Maps and you agree to
            Google’s terms through our App, Google is not a party to this
            contract and has no obligations under these Terms (and vice versa,
            you cannot hold Google accountable under our contract with you; your
            recourse would be under Google’s own terms).
          </p>

          {/* 9. Liability, Disclaimers, and Limitation of Liability */}
          <h2>9. Liability, Disclaimers, and Limitation of Liability</h2>
          <p>
            PLEASE READ THIS SECTION CAREFULLY – it limits the liability of the
            Company and outlines disclaimers of warranties. By using the App,
            you acknowledge and agree to these disclaimers and limitations.
          </p>

          <h3>9.1 Provision of Service “As Is”:</h3>
          <p>
            WashMinute provides the App and Services on an “as is” and “as
            available” basis. While we endeavor to keep our platform reliable,
            accessible, and safe, we make no guarantees or warranties of any
            kind, whether express, implied, or statutory, as to the accuracy,
            quality, or reliability of the App or the services provided by
            Washmen. This includes, but is not limited to, implied warranties of
            merchantability, fitness for a particular purpose, non-infringement,
            or any guarantee that the Services will meet your expectations or
            requirements. Specifically, we do not warrant that: (a) the App will
            be uninterrupted, error-free, or secure at all times; (b) any
            results (such as cleaning outcome or time estimates) obtained from
            using the Service will be accurate or reliable; (c) the quality of
            any services, information, or other material obtained through the
            App will meet your expectations; or (d) any defects or errors in the
            software will be corrected immediately. While we aim to correct
            known issues promptly, the App’s operation may be affected by
            numerous factors outside our control (including, for example, mobile
            network issues or device compatibility problems).
          </p>

          <h3>9.2 Limitation of Liability for the Company:</h3>
          <p>
            To the maximum extent permitted by law, WashMinute (the Company) and
            its affiliates, officers, employees, and agents shall not be liable
            for any indirect, incidental, special, consequential, or exemplary
            damages arising out of or in connection with your use of the App or
            Services, even if advised of the possibility of such damages. This
            includes, without limitation, damages for lost profits, lost data,
            personal injury, property damage, or losses resulting from use or
            inability to use the App, reliance on any information obtained from
            us or through the Service, unauthorized access, or conduct of any
            third party on the App.
            <br />
            Our total aggregate liability to any user (Client or Washman) for
            all claims arising from use of the App or Services shall be limited
            to the amount of fees (if any) that you paid to us for use of the
            Service in the 6 months preceding the event giving rise to
            liability, or USD $100, whichever is greater. In cases where you
            have not paid any fees to the Company (for example, Clients
            currently pay Washmen directly, and use of the app itself is free),
            the Company’s liability will be close to zero (aside from the extent
            not legally excludable).
          </p>

          <h3>9.3 Liability for Services (Clients and Washmen):</h3>
          <p>
            Because the actual car wash services are carried out by Washmen (who
            are independent providers), the Company is not liable for the acts
            or omissions of any Washman or Client in relation to the services.
            We do not assume liability for any damage to a vehicle, personal
            injury, or other loss that may occur during or as a result of a car
            wash service requested through our App. For example, if a Washman
            causes a scratch on a Client’s car, or a Client’s pet (or another
            person) interferes and causes injury to the Washman, those are
            matters that should be resolved between the parties involved
            (possibly through the Washman’s insurance, or other legal means).
            WashMinute is not responsible for resolving such disputes, but we
            may, at our discretion, facilitate communication or provide
            information we have (like service records) to help resolve an issue.
          </p>

          <h3>9.4 No Liability for Missed Appointments or Delays:</h3>
          <p>
            WashMinute is not liable for any costs, losses, or damages incurred
            due to a Washman’s failure to arrive at the scheduled time or at
            all. While we expect Washmen to honor their commitments, there might
            be situations (traffic accidents, emergencies, technical issues)
            that lead to delays or no-shows. If a Washman fails to show or is
            significantly late, the Client’s sole remedy is to either reschedule
            the service or cancel the request (with no charge, of course). Any
            compensation for inconvenience (such as a discount on a future
            service) is at the Company’s discretion; it is not guaranteed or
            required. Similarly, WashMinute is not liable to Washmen if a Client
            is absent or unwilling to get the service upon arrival – the
            platform will attempt to enforce our cancellation policies (as in
            Section 5) to penalize no-shows, but the Company doesn’t guarantee
            payment for a job that didn’t happen.
            <br />
            Neither the Company nor its partners will be liable for any indirect
            consequences of missed appointments, such as a Client missing an
            important event because their car wasn’t cleaned, or a Washman
            losing potential other gigs. Users should schedule with some
            flexibility for unforeseen circumstances.
          </p>

          <h3>9.5 User’s Responsibility for Liability:</h3>
          <p>
            Clients are responsible for any damage they may cause intentionally
            or negligently to the Washman or the Washman’s equipment. For
            instance, if a Client, through negligence, causes injury to a
            Washman (say, by moving the car during the wash without warning),
            the Client may be held liable for that. Washmen are similarly
            responsible for any damage they intentionally or negligently cause
            to the Client’s property beyond normal wear and tear of the service.
            These are personal liabilities of the users involved; the Company is
            not a guarantor or insurer of either party’s conduct.
          </p>

          <h3>9.6 Indemnification:</h3>
          <p>
            You agree to indemnify, defend, and hold harmless WashMinute and its
            affiliates, and each of their officers, directors, employees, and
            agents, from and against any and all claims, liabilities, damages,
            losses, and expenses (including reasonable attorneys’ fees) arising
            out of or in connection with: (a) your use of the App or Services
            (including any contracts or interactions with other users); (b) your
            violation of any provision of these Terms or of any applicable law
            or regulation; (c) your violation of any rights of any third party
            (for example, a violation of privacy or property rights of a third
            party at a service location); or (d) any content you submit or
            transmit through the App (such as reviews or feedback). This
            indemnification obligation will survive any termination of your
            relationship with WashMinute or use of the App. We will provide
            notice to you of any such claim or demand that we receive, and may
            require your assistance, at your expense, in defending such claim.
            You are free to conduct your own defense of any claim, but you may
            not settle any claim that imposes any obligation or liability on
            WashMinute without our prior written consent.
          </p>

          <h3>9.7 Release:</h3>
          <p>
            In the event that you have a dispute with one or more users of the
            App (e.g., a dispute between a Client and a Washman regarding a
            service or payment), you release WashMinute (and our officers,
            directors, agents, subsidiaries, joint ventures, and employees) from
            any and all claims, demands, and damages of every kind and nature,
            known or unknown, arising out of or in any way connected with such
            disputes. This means that, to the maximum extent permitted by law,
            you waive the application of any law that would otherwise limit the
            coverage of this release to include only those claims which you may
            know or suspect to exist in your favor at the time of agreeing to
            this release. (If you are a resident of California, as an example,
            you waive California Civil Code §1542, which says a general release
            does not extend to claims which the creditor does not know or
            suspect to exist in his favor at the time of executing the release.)
            Essentially, you acknowledge that other users are not controlled by
            us, and any issues with them must be resolved without seeking
            damages from the Company.
          </p>

          <h3>9.8 Disclaimer of Warranties:</h3>
          <p>
            WashMinute disclaims all warranties and conditions, whether express,
            implied, or statutory, to the extent permitted by law. This includes
            any implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement. We do not guarantee that the services
            obtained through the App will meet your requirements or
            expectations. Use of the App and any services obtained through it is
            at your own risk. Some jurisdictions do not allow the exclusion of
            certain warranties, so some of the above exclusions may not apply to
            you. In such cases, any warranties required by law are limited in
            duration to the minimum period legally required and, if no minimum
            is stated, to 30 days from first use of the App.
          </p>

          <h3>9.9 Limitation of Liability (continued):</h3>
          <p>
            If despite the disclaimers above, WashMinute is found to be liable
            to you for any damage or loss arising from or connected to your use
            of the App or services, our total liability shall not exceed the
            amount of service fees you have paid to WashMinute (if any) or one
            hundred US dollars ($100), whichever is greater. Since currently
            Clients do not pay any fees to WashMinute (only to Washmen directly),
            our liability in most cases will be $0. WashMinute has no liability
            for any incidental or consequential damages, as stated before. This
            limitation is a fundamental part of the bargain between you and us
            and shall apply to the maximum extent permitted by law, even if a
            remedy fails of its essential purpose.
          </p>

          <h3>9.10 No Guarantee of Service Availability:</h3>
          <p>
            We are not liable for any failure or delay in performance of the App
            or Services to the extent such failure is due to causes beyond our
            reasonable control, such as acts of God, natural disasters, war,
            terrorism, civil disorder, technical failures, power outages, or any
            other force majeure event. We do not guarantee that the platform
            will always be available or that requests will always be fulfilled.
            There may be periods of downtime for maintenance or unforeseen
            outages. We will try to inform users of any major outages or suspend
            new requests when we know the system is not functioning correctly,
            but any such downtime does not constitute a breach of these Terms.
            In summary, WashMinute’s role is primarily to facilitate connections
            between Clients and Washmen. You agree that our liability is limited
            and that you assume the risks of using an on-demand service
            involving third-party interactions. If you are unhappy with the
            service or the platform, your remedy is to discontinue use of the
            App (and, if applicable, pursue any claim directly with the party
            who caused you harm, but not with WashMinute, to the extent allowed
            by law).
          </p>

          {/* 10. Compliance with Google Play Policies */}
          <h2>10. Compliance with Google Play Policies</h2>
          <p>
            We strive to ensure that WashMinute and these Terms and Conditions
            fully comply with all Google Play Store policies and guidelines for
            app distribution. Some key points regarding this compliance:
            <br />
            <strong>Content Policies:</strong> The App does not contain or
            promote illegal activities, sexually explicit content, hate speech,
            harassment, or any other content prohibited by Google Play’s
            Developer Program Policies. Users are also forbidden (as per Section
            6 of these Terms) from using the platform to engage in any
            prohibited content or behavior. We reserve the right to remove any
            content or ban any user that violates Google Play’s content policies
            or these Terms.
            <br />
            <strong>User Data Policies:</strong> We have implemented a
            comprehensive Privacy Policy and data handling practices consistent
            with Google Play’s requirements (including the requirement to
            disclose how we collect, use, and share user data, and to secure
            user consent where needed). As mentioned in Section 7, we also
            respect Google’s Data safety and Account deletion requirements by
            allowing users to request deletion of their data. We do not access
            sensitive information (like contacts, photos, etc.) without explicit
            permission and a clear purpose that benefits the user experience.
            Any third-party code or SDK in our App is vetted to comply with
            Google Play’s policies as well.
            <br />
            <strong>Payments Compliance:</strong> Google Play has specific rules
            about payments (for example, using Google Play’s in-app billing for
            digital goods). In our case, since the transactions are for physical
            services (car washes) and are completed outside the app (cash on
            delivery), this is compliant with Google Play’s guidelines (Google
            generally allows physical service payments to be handled
            externally). If we introduce in-app payments or purchases in the
            future, we will ensure they follow Google Play’s rules (for
            instance, if any in-app purchase of a digital good or currency
            occurs, it will use Google Play Billing as mandated).
            <br />
            <strong>Third-Party Compliance:</strong> By integrating Google Maps
            and other services as described, we also ensure that we adhere to
            Google’s API usage terms. We have included the required disclosures
            for Google Maps (see Section 8). Any analytics or advertising SDKs
            used are compliant with Google’s policies on user data and
            advertising (for example, we will honor “Do Not Sell My Info” or
            privacy settings and use advertising IDs according to policy, if
            applicable).
            <br />
            <strong>App Store Listing Accuracy:</strong> We ensure that our
            Google Play store listing (app description, screenshots, etc.)
            accurately represents the functionality of the app and does not
            mislead users. The Terms and Conditions here are available to users
            (usually via a link in the app or store listing) so that users can
            read about their rights and obligations clearly. We also commit to
            keeping our store listing updated with contact information and a
            link to our privacy policy, as required by Google.
            <br />
            <strong>Policy Updates:</strong> Google Play’s policies may change
            over time. We commit to staying informed of these changes and
            updating our App and/or these Terms and our practices as needed to
            maintain compliance. For example, if Google Play introduces new
            rules about specific permissions or about subscription services, we
            will implement those requirements promptly. Users will be notified
            of any significant changes in our Terms that affect their rights or
            obligations, especially if required by Google’s User Data or Terms
            guidelines.
            <br />
            <strong>User Complaints and Issue Resolution:</strong> We comply
            with Google Play’s requirement to have a method for users to contact
            us (see Section 12 for our contact info). If a user or Google
            contacts us about a violation of policy (for instance, a copyright
            infringement claim or a user data concern), we will address it
            promptly and in good faith. We also acknowledge that Google may take
            action (including removing our app from the store) if we do not
            comply with their policies, and thus we are highly motivated to
            adhere strictly to all requirements.
          </p>

          {/* 11. Modification of Terms and Service */}
          <h2>11. Modification of Terms and Service</h2>
          <p>
            <strong>Changes to These Terms:</strong> The Company reserves the
            right to modify or update these Terms and Conditions at any time. If
            we make material changes (i.e., changes that significantly affect
            your rights or obligations), we will notify users by appropriate
            means – for example, by posting a notice within the App, updating
            the “Last Updated” date at the top of this document, and/or sending
            an email to the address associated with your account. It is your
            responsibility to review any updated Terms. Continued use of the App
            after any changes to the Terms have been posted constitutes your
            acceptance of those changes. If you do not agree with an update, you
            should cease using the App and, if desired, request account deletion
            as per Section 7.
            <br />
            <strong>Changes to the Service:</strong> We are constantly improving
            and evolving our services. We may add, modify, or remove features
            from the App at any time. We may also cease operations or shut down
            the App entirely, though we certainly hope that never happens. If we
            believe a change to the service or a discontinuation will have a
            significant impact on users, we will attempt to give advance notice
            (e.g., via in-app notification or email). However, there may be
            instances (such as security fixes or beta feature removals) where
            immediate changes occur without notice. The Company shall not be
            liable for any modification, suspension, or discontinuation of the
            Service, though if any prepaid services were affected by a
            discontinuation, we would handle refunds on a case-by-case basis
            (since currently there are no prepaid services, this is a low risk).
            <br />
            <strong>App Updates:</strong> When we release updates or new
            versions of the App (through Google Play or other distribution
            channels), you are encouraged to download and install them to
            benefit from new features and security enhancements. Depending on
            your device settings, the App might update automatically or prompt
            you to update. Note that older versions of the App may cease to
            function properly if you do not update, or may no longer be
            supported. By using the App, you consent to receiving and installing
            updates (including automatically if enabled on your device).
            <br />
            <strong>Termination of Use:</strong> You may stop using the App at
            any time and uninstall it. We do not contractually obligate users to
            continue using the service. However, if you have any pending
            obligations (for example, an upcoming appointment you booked or
            money owed to a Washman), you should fulfill those or cancel
            appropriately before leaving the platform. Similarly, we reserve the
            right to terminate or suspend your access to the App (and your
            account) if you violate these Terms or for any other reason at our
            sole discretion (including inactivity of an account, or if required
            by law to bar your access). If we terminate your account for cause
            (e.g., violation of Terms or illegal activity), we may not allow
            re-registration. If we terminate without cause (for example,
            discontinuing the service entirely), we will try to give notice.
            Sections of these Terms that by their nature should survive
            termination (such as indemnities, liability limitations, etc.) will
            survive.
          </p>

          {/* 12. Miscellaneous Provisions */}
          <h2>12. Miscellaneous Provisions</h2>
          <p>
            <strong>12.1 Governing Law and Jurisdiction:</strong> Unless
            otherwise required by local law, these Terms and any dispute or
            claim arising out of or in connection with them or the use of the
            Services shall be governed by and construed in accordance with the
            laws of [the jurisdiction where the Company is registered/located,
            e.g., the Kingdom of Morocco if WashMinute is a Moroccan company]
            without regard to its conflict of law principles. You agree that any
            legal action or proceeding arising under these Terms or related to
            your use of the App shall be brought exclusively in the courts of
            that jurisdiction. You consent to the personal jurisdiction of such
            courts and waive any objections (e.g., on grounds of venue or
            inconvenient forum) to the exercise of jurisdiction by those courts.
            If you use the App in a jurisdiction that does not allow this choice
            of law or forum clause, then local law and jurisdiction may apply to
            the extent required.
            <br />
            <strong>12.2 Severability:</strong> If any provision of these Terms
            is held by a court of competent jurisdiction to be invalid, illegal,
            or unenforceable, that provision shall be enforced to the maximum
            extent permissible, and the remaining provisions of these Terms
            shall remain in full force and effect. The invalid provision will,
            at our discretion, be modified so as to be rendered enforceable
            while reflecting as closely as possible the original intent (or, if
            not possible, deemed severed from these Terms).
            <br />
            <strong>12.3 No Waiver:</strong> No failure or delay by the Company
            in exercising any right, power, or remedy under these Terms shall
            operate as a waiver of that right, power, or remedy. Any waiver must
            be explicit and in writing to be effective. The single or partial
            exercise of any right, power, or remedy by us does not preclude
            further exercise of the same or any other right, power, or remedy.
            <br />
            <strong>12.4 Assignment:</strong> You may not assign or transfer
            your rights or obligations under these Terms to any third party
            without our prior written consent. The Company may assign or
            transfer these Terms (in whole or in part) to an affiliate or in
            connection with a merger, acquisition, reorganization, or sale of
            assets, or by operation of law or otherwise, and you hereby consent
            to such assignment. These Terms shall inure to the benefit of and be
            binding upon the parties and their permitted successors and assigns.
            <br />
            <strong>12.5 Entire Agreement:</strong> These Terms (along with any
            additional rules, policies, and supplemental terms referenced herein
            or provided in the App, such as the Privacy Policy) constitute the
            entire agreement between you and WashMinute regarding the use of the
            App and Services. They supersede any prior agreements or
            understandings, whether written or oral, relating to the subject
            matter. In case of a conflict between these Terms and any other
            policy or guideline in the App, these Terms will generally prevail
            unless the other document is explicitly stated to override the Terms
            in certain aspects.
            <br />
            <strong>12.6 Relationship of Parties:</strong> Nothing in these
            Terms shall be construed as creating a joint venture, partnership,
            employment, or agency relationship between you and the Company.
            Washmen are independent contractors and not employees or agents of
            the Company (unless specifically designated otherwise). You and the
            Company are independent contracting parties. You cannot represent or
            bind the Company in any way, and vice versa except as laid out in
            these Terms.
            <br />
            <strong>12.7 Contact Information:</strong> If you have any
            questions, concerns, or feedback about these Terms or the WashMinute
            Services, or if you need to contact us for any reason (including to
            provide notices, request data deletion, or report violations), you
            can reach us at:
            <br />
            WashMinute Support Team
            <br />
            Email: contact@washminute.com 
            <br />
            Address: 345 AL MASSAR ROUTE DE SAFI ,
            Marrakech (Carrefour Targa)
            Morroco
            <br />
            Customer Service Phone: +212-618509180
            <br />
            We will make our best effort to respond to inquiries within a
            reasonable time. Please include in any communication your name,
            contact information, and a clear description of your question or
            issue.
            <br />
            <strong>12.8 Language:</strong> These Terms and Conditions are
            written in English. If we provide a translation in another language,
            it is for convenience only. In the event of any conflict between the
            English version and a translated version, the English version will
            control, to the extent not prohibited by local law in your
            jurisdiction.
          </p>

        </div>
      </section>

      <ToastContainer />
    </>
  );
}

export default First;
