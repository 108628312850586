
import Header from '../Menhometwo/Headertwo'
import First from '../Pages/AccountDeleteRequest/First'
import { useTranslation } from 'react-i18next';
const AccountDeleteRequest = () => {
    const { t } = useTranslation();


    return (
        <>
            <Header/>
            <First />
          
        </>
    )
}
export default AccountDeleteRequest