import React from 'react'
import { Link } from 'react-router-dom'
import "./banner2.css" ;

function Banner2() {
  return (
    <div className="car-container">
    <img
      src="assets/images/image.png" // Replace with the correct path to your image
      alt="Car Image"
      className="car-image"
    />
    <div className="label-container">
      <span className="label" style={{ top: "10%", left: "15%" }}>
        Aspiration intérieure <span className="dot"></span>
      </span>
      <span className="label" style={{ top: "20%", left: "12%" }}>
        Dépoussiérage <span className="dot"></span>
      </span>
      <span className="label" style={{ top: "40%", left: "9%" }}>
        Nettoyage extérieur <span className="dot"></span>
      </span>
      <span className="label" style={{ top: "50%", left: "6%" }}>
        Nettoyage des vitres <span className="dot"></span>
      </span>
      <span className="label" style={{ top: "65%", left: "13%" }}>
        Nettoyage des roues <span className="dot"></span>
      </span>
      <span className="label" style={{ top: "75%", left: "20%" }}>
        Finitions <span className="dot"></span>
      </span>
    </div>
    <a
      href="https://wa.me/212618509180"
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "inline-block",
        padding: "10px 20px",
        border: "2px solid #007BFF", // Blue border
        color: "#007BFF", // Blue text color
        textDecoration: "none",
        borderRadius: "25px", // Fully rounded corners
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "transparent", // No background
        transition: "all 0.3s ease", // Smooth hover effect
      }}
      onMouseEnter={(e) => {
        e.target.style.backgroundColor = "#007BFF"; // Blue background on hover
        e.target.style.color = "#FFF"; // White text on hover
      }}
      onMouseLeave={(e) => {
        e.target.style.backgroundColor = "transparent"; // Revert to transparent
        e.target.style.color = "#007BFF"; // Revert to blue text
      }}
    >
      WhatsApp
    </a>
  </div>
  
  )
}

export default Banner2