import React from "react";
import Navbar from "./header";
import Banner from "./banner";
import ServiceDetails from "./ServiceDetails";
import Banner2 from "./banner2";
// import "./Main.css";

const Main = () => {
  return (
    <div className="main-container">
      {/* Navbar Component */}
      <Navbar />
      
      {/* Banner Component */}
      <Banner />
      <Banner2 />
      <ServiceDetails />
    </div>
  );
};

export default Main;
