import React from 'react';
import { useTranslation } from 'react-i18next';

const Modal = ({ isOpen, close, children }) => {
  const { t } = useTranslation();
  if (!isOpen) return null;


  return (
    <>
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Overlay to darken the background
        zIndex: 999 // Ensure it's behind the modal content but above everything else
      }} onClick={close}></div>
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '0', // Remove padding from the container
        zIndex: 1000, // Above the overlay
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '90%', // Adjusted for responsiveness
        maxWidth: '500px' // Max width for larger screens
      }}>
        <div style={{
          height: '40px',
          backgroundColor: 'rgb(252, 132, 68 , 0.7)', // Website color
          borderTopLeftRadius: '10px', // Match the modal's border radius
          borderTopRightRadius: '10px', // Match the modal's border radius
        }}></div>
        <div style={{
          padding: '20px' // Apply padding here to only affect the content
        }}>
          <button onClick={close} style={{
            position: 'absolute',
            color : 'white' ,
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer'
          }}>×</button>
          {children}
          <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
  {/* First Button */}
  <button className="thm-btn" onClick={close}>
    {t('book')}
  </button>

  {/* Second Button */}
  <a href="/daba" style={{ textDecoration: 'none' }}>
    <button
      className="thm-btn"
      onClick={close}
      style={{
        backgroundColor: '#0072ce', // Blue background
        color: '#fff', // White text color
        border: 'none', // Remove any border
        padding: '10px 20px', // Ensure proper padding
        borderRadius: '5px', // Optional: Add some rounded corners
        cursor: 'pointer', // Ensure it looks clickable
      }}
    >
      {t('book1')}
    </button>
  </a>
</div>

        </div>
      </div>
    </>
  );
};

export default Modal;
