import React from 'react'



import { Link, useLocation } from 'react-router-dom';

function Main({title, subtitle, newtitle,bg}) {
    const location = useLocation(); 
    return (
        <>
            <section className="page-header">
            <div className="page-header__bg" style={{ backgroundImage: `url(${bg})` }}></div>

                <div className="container">
                    <h2>{subtitle}</h2>
                    <ul className="thm-breadcrumb list-unstyled">
                        <li><Link to="/">{title}</Link ></li>
                        <li>/</li>
                        <li><span>{newtitle}</span></li>
                      
                    </ul>
                  
                </div>
            </section>
        </>
    )
}

export default Main