import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function First() {
  // Form state variables
  const [userName, setUserName] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Construct the request payload
    const requestData = {
      user_name: userName,
      reason: reason,
      user_cognito_id: "accountdeleterequest", // Temporary Cognito ID
    };

    try {
      const response = await axios.post(
        "https://internal.washminute.com/api/account-deletion",
        requestData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.success) {
        toast.success("Your account deletion request has been submitted.");
        setUserName("");
        setReason("");
      } else {
        
        toast.error("Failed to submit request. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting deletion request:", error);
      toast.error("An error occurred. Please try again later.");
    }

    setLoading(false);
  };

  return (
    <>
      <section className="terms-conditions">
        <div className="container">
          {/* Title */}
          <h1>WashMinute App – Account Deletion Request</h1>
          <p>Last Updated: February 28, 2025</p>

          {/* Introduction */}
          <h2>Submit Your Account Deletion Request</h2>
          <p>
            We understand that you may want to delete your WashMinute account.
            Once your request is submitted, our team will process it within **7 business days**.
            Please note that **this action is irreversible**, and all associated data will be permanently removed.
          </p>

          {/* What Happens After Submitting a Deletion Request */}
          <h2>What Happens Next?</h2>
          <ul>
            <li>Your request is logged in our system.</li>
            <li>We verify your request and process account removal within 7 business days.</li>
            <li>You will receive a confirmation email once your account has been successfully deleted.</li>
          </ul>

          {/* Retention Policy */}
          <h2>What Data is Deleted?</h2>
          <p>
            When your account is deleted, we will remove all your personal details, including:
          </p>
          <ul>
            <li>Your profile information (name, email, phone number).</li>
            <li>Your car wash history and preferences.</li>
            <li>Any saved payment methods (if applicable in the future).</li>
          </ul>
          <p>
            However, some **transaction records** (such as receipts) may be retained for legal purposes, 
            but they will no longer be linked to your personal identity.
          </p>

          {/* Form Section */}
          <h2>Request Account Deletion</h2>
          <p>
            Please provide your **registered username** and a **reason** for account deletion. 
            This helps us improve our services.
          </p>

          <form
            className="contact-form-validated contact-one__form"
            onSubmit={handleSubmit}
          >
            <div className="row">
              {/* User Name Field */}
              <div className="col-md-12">
                <input
                  type="text"
                  placeholder="Your Username"
                  name="user_name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
              </div>

              {/* Reason for Deletion Field */}
              <div className="col-md-12">
                <textarea
                  name="reason"
                  placeholder="Reason for deleting your account"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                ></textarea>
              </div>

              {/* Submit Button */}
              <div className="col-md-12">
                <button type="submit" className="thm-btn" disabled={loading}>
                  <i className="far fa-arrow-alt-circle-right"></i>{" "}
                  {loading ? "Submitting..." : "Submit Request"}
                </button>
              </div>
            </div>
          </form>

          {/* Contact Information */}
          <h2>Need Help?</h2>
          <p>
            If you experience any issues, please contact our support team:
          </p>
          <ul>
            <li>Email: <a href="mailto:contact@washminute.com">contact@washminute.com</a></li>
            <li>Phone: +212-618509180</li>
            <li>Contact Form: <a href="https://washminute.com/contact" target="_blank">https://washminute.com/contact</a></li>
          </ul>
        </div>
      </section>

      <ToastContainer />
    </>
  );
}

export default First;
